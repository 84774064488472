.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.photo-container {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 5px;
}

.uploaded-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.remove-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    padding: 2px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.photo-container:hover .remove-icon {
    opacity: 1;
}

.feedback{
    

}

.assessment-photo{
    width: 315px;
    height: 315px;
    object-fit: cover;
    object-position: top;
    border-radius: 15px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    max-width: 80%;
    max-height: 80%;
    overflow-y: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-image {
    max-width: 100%;
    max-height: 70vh;
    object-fit: contain;
}

.modal-header{
    display: flex;
    justify-content: flex-end;
}

.close-button {
    /* position: absolute; */
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #a2a2a2;
    border-radius: 50%;
    padding: 5px;
}

.close-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.close-button-container{
    display: flex;
    justify-content: flex-end;
    top: 10px;
    right: 10px;
    /* padding-bottom: 5px; */
}

.loading-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.gallery{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.assessment-card{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.25);
    padding: 20px;
    border-radius: 15px;
}

.assessment-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.feedback-section{
    text-align: left;
}

h4{
    padding: none;
    margin: none;
    margin-block: 0;
}


.rating-section{
    display: flex;
    justify-content: space-between;
    width: 100%;
}



.column{
    width: 140px;
}

.rating-tag{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.rating-tag-name{
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.rating-tag-score{
    font-weight: 600;
}

.rating-tag-score.low{
    color: red;
}

.rating-tag-score.medium{
    color: orange;
}

.rating-tag-score.high{
    color: green;
}

.overall-score-container{
    position: absolute;
    top: -20px;  /* Half of the height */
    right: -20px;  /* Half of the width */
}

.overall-score {

    border-radius: 50%;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
    font-size: 28px;
    font-weight: 600;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.overall-score.low{
    background-color: #F87171;
}

.overall-score.medium{
    background-color: #F9D988;
}

.overall-score.high{
    background-color: #BAEAB9;
}   

.overall-score.very-high{
    background-color: #8acb89;
}   


.like-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    transition: all 0.3s ease;
}

.like-button:hover {
    transform: scale(1.1);
}

.like-count {
    margin-left: 5px;
    font-size: 14px;
    font-weight: bold;
}

.liked {
    color: #e91e63;
}

.not-liked {
    color: #757575;
}

.upload-container{
    width: calc(min(100%, 400px));
    margin: 0 auto;
}


.upload-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.upload-button{
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.upload-button:hover{
    background-color: #333;
}



/* Navbar */
.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 60px;
    position: sticky;
    top: 0;
    z-index: 1000;
}

.navbar .logo img{
    height: 32px;
    width: 32px;
}

.navbar-left{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-left: 5px;
}

.navbar-right{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.navbar button{
    cursor: pointer;
}

.home-button{
    border: none;
    background-color: #fff;
    font-size: 16px;
    font-weight: 600;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
}

.upload-route-button{
    color: #fff;
    background-color: #000;
    padding: 10px 15px;
    border-radius: 20px;
    cursor: pointer;
    height: 40px;
    border: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.upload-route-button:hover{
    background-color: #333;
}


.upload-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.photo-grid{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.uploaded-photo{
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 15px;
}

.upload-submit-button{
    background-color: #000;
    color: #fff;
    padding: 10px 15px;
    border-radius: 20px;
    cursor: pointer;
    height: 40px;
    border: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 200px;
}

.error-container{
    width: calc(min(85%, 360px));
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    margin-top: 20px;
}

.error-container p{
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
}



/* dropdown  */
.sort-section {
    position: relative;
    z-index: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7px;
}

.custom-dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    background-color: #f1f1f1;
    color: #333;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    width: 200px;

}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    border-radius: 4px;
    overflow: hidden;
    width: 200px;

}

.dropdown-item {
    padding: 10px 15px;
    cursor: pointer;
}

.dropdown-item:hover {
    background-color: #f1f1f1;
}

.privacy-button{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: #000;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 20px;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #A9A9A9;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    color: white;
}

input[type="checkbox"]:checked {
    background-color: #000;
    color: white;
    border: 1px solid #000;
}

input[type="checkbox"]:checked::before {
    content: "";
    display: block;
    width: 4px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: relative;
    top: 1px;
    left: 6px;
}

/* input[type="checkbox"]:checked + label::after {
    content: '✓';
    display: inline-block;
    position: absolute;
    left: 4px;
    top: -2px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border: 1px solid #A9A9A9;
} */

.checkbox-container{
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    padding: 0;
    margin: 0;
}

.checkbox{
    width: 20px;
    padding: 0px;
    margin: 0px;
}

.arrow-container{
    padding-bottom: 20px;
}

.arrow{
    font-size: 100px;
    font-weight: 600;
}

.overall-score-change-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.optimized-profile-score-improvement-container{
    width: calc(min(85%, 360px));
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.optimized-profile-score-improvement-container h1{
    margin: 0;
    padding: 0;
}

.view-all-button{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 15px;
    border: none;
    cursor: pointer;
    background-color: #e4e4e4;
    color: #000;
}

.view-all-button:hover{
    background-color: #d4d4d4;
}

.gallery-header{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.gallery-header h4{
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
}

.gallery-container{
    width: calc(min(85%, 360px));
    margin: 0 auto;
}

.score-before-after-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 130px;
}

.score-before-after-container h6{
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
}

.optimized-profile-score-improvement-container h3{
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 700;
}

.improvement-message-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
}

.improvement-message-container h4{
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 500;
}

.improvement-message-container strong{
    font-weight: 800;
    color: #008571;
}

.photo-uploader-page{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 92vh;
    align-items: center;
}

.footer{
    max-width: 70vw;
}